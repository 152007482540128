import { useEffect, useRef, useState } from "react";
import MissingData from "../../component/container/missing-data-container";
import TableCompany from "../../component/table/table-company";
import Footer from "../footer/footer";

const TabCompany = (props) => {

    const channelRef = useRef();
    const cityRef = useRef();
    const stateRef = useRef();
    const propRef = useRef();
    const [filteredList, setFilteredList] = useState([]);

    const allStates = { text: 'All States', value: 'all' };
    const allCities = { text: 'All Cities', value: 'all' };
    const allChannels = { text: 'All Channels', value: 'all' };
    const isIframe = true;
    //sessionStorage.getItem("isIframe") != null ? (sessionStorage.getItem("isIframe") === "true") : false;

    // get all cities from input
    const getCities = (propertyList) => {
        let cities = [];
        for (var property of propertyList) {
            if (cities.find((p) => p.value === property.cityName)) {
              continue;
            }
            cities.push({ text: property.cityName, value: property.cityName });
        }
        cities.sort((a, b) => a.value.localeCompare(b.value));
        return cities;
    };

    // get all states from input
    const getStates = (propertyList) => {
        let states = [];
        for (var property of propertyList) {
            if (states.find((p) => p.value === property.stateName)) {
              continue;
            }
            states.push({ text: property.stateName, value: property.stateName });
        }
        states.sort((a, b) => a.value.localeCompare(b.value));
        return states;
    };

    // get all channels from input
    const getChannels = (propertyList) => {
        let channels = [];
        for (var property of propertyList) {
            if (property.channelList) {
                for (var channel of property.channelList) {
                    if (channels.find((c) => c.value === channel.leadChannelId.toString())) {
                        continue;
                    }
                    channels.push({ text: channel.leadChannelName, value: channel.leadChannelId.toString() });
                }
            }
        }
        channels.sort((a, b) => a.text.localeCompare(b.text));
        return channels;
    };

    const resetFilters = (cleanInput) => {
        channelRef.current.value = allChannels.value;
        cityRef.current.value = allCities.value;
        stateRef.current.value = allStates.value;

        if (cleanInput) {
            propRef.current.value = null;
        }

        setFilteredList(props.dashboardData.propertyList);
    };

    const applyFilter  = (stateValue, cityValue, channelValue, searchValue) => {
        return props.dashboardData.propertyList.filter((property) => {
            const stateMatch = stateValue === 'all' || property.stateName === stateValue;
            const cityMatch = cityValue === 'all' || property.cityName === cityValue;
            const channelMatch = channelValue === 'all' || (property.channelList && property.channelList.find((c) => c.leadChannelId.toString() === channelValue))
            const searchMatch = !searchValue || property.propertyName.toLowerCase().includes(searchValue.toLowerCase());
            return stateMatch && cityMatch && channelMatch && searchMatch;
        });
    };

    const handleSearchChange = (e) => {
        const searchValue = e.target.value;

        resetFilters();
        if (searchValue && searchValue.length > 0) {
            setFilteredList(applyFilter(allStates.value, allCities.value, allChannels.value, searchValue));
        } else {
            setFilteredList(props.dashboardData.propertyList);
        }
    };
    useEffect(() => {
            setFilteredList(props.dashboardData.propertyList);
    },[props.dashboardData.propertyList]);
    
    useEffect(() => {
        // initialize drop downs
        channelRef.current.options = [allChannels].concat(getChannels(props.dashboardData.propertyList));
        channelRef.current.value = allChannels.value;

        cityRef.current.options = [allCities].concat(getCities(props.dashboardData.propertyList));
        cityRef.current.value = allCities.value;

        stateRef.current.options = [allStates].concat(getStates(props.dashboardData.propertyList));
        stateRef.current.value = allStates.value;

        // initiliaze
        setFilteredList(props.dashboardData.propertyList);

        // handle events
        channelRef.current.addEventListener("raulChange", (e) => {
            setFilteredList(applyFilter(stateRef.current.value, cityRef.current.value, e.detail.value, propRef.current.value));
        });
        cityRef.current.addEventListener("raulChange", (e) => {
            setFilteredList(applyFilter(stateRef.current.value, e.detail.value, channelRef.current.value, propRef.current.value));
        });
        stateRef.current.addEventListener("raulChange", (e) => {
            const list = applyFilter(e.detail.value, allCities.value, channelRef.current.value, propRef.current.value);

            cityRef.current.options = [allCities].concat(getCities(list));
            cityRef.current.value = allCities.value;
            setFilteredList(list);
        });
    }, []);

    return (
        <div className="company-tab">
            <MissingData propertyList={props.dashboardData.propertyList} companyName = {props.dashboardData.companyName} mcUrl = {props.dashboardData.mcUrl} />

            <div className="filters-container r-mt-10">
                <div class="r-flex r-justify-between">
                    <div class="r-w-1/5">
                        <raul-input type="search">
                            <input type="text" placeholder="Properties filter" ref={propRef} onChange={handleSearchChange}/>
                        </raul-input>
                    </div>
                    <div class="r-w-1/5 r-ml-4">
                        <raul-select placeholder="State" ref={stateRef}></raul-select>
                    </div>
                    <div class="r-w-1/5 r-ml-4">
                        <raul-select placeholder="City" ref={cityRef}></raul-select>
                    </div>
                    <div class="r-w-1/5 r-ml-4">
                        <raul-select placeholder="Channel" ref={channelRef}></raul-select>
                    </div>
                    <raul-button variant="primary" class="r-ml-6" onClick={() => resetFilters(true)}>Reset Filters</raul-button>
                </div>
            </div>
            <div>
                <TableCompany propertyList={filteredList} changeDashboardProperty={props.changeDashboardProperty}/>
                { !isIframe &&
                    <Footer />
                }
            </div>
        </div>
    );
};

export default TabCompany;
