import React, { useEffect, useState} from 'react';
import { useRef,useContext } from 'react';
import { appContext } from "../../App";
import AppConfig from "../../app-config";


const Settings = (props) => {
  const { http } = useContext(appContext);
  const asideRef = useRef();
  const btnRef = useRef();
  const closeRef = useRef();
  const imageRef = useRef();
  const readyRef = useRef();
  const configAsideRef = useRef();
  const closeBtnRef = useRef();
  const [isSaved, setIsSaved] = useState(false);
  const [message,setMessage] = useState("");

  useEffect(() => {
      btnRef.current.addEventListener('click', function() {
        asideRef.current.open();
        setIsSaved(false);
        setMessage("");
      });
      closeRef.current.addEventListener('click', function() {
        asideRef.current.close();
      });
      closeBtnRef.current.addEventListener('click', () => configAsideRef.current.close());
  }, []);
  
  const openEditConfig =(property) =>{
    if (property) {
        imageRef.current.checked = (property.syndicateMedia === "true");
        readyRef.current.checked = (property.syndicationReady === "true");
        configAsideRef.current.open();
    }
  }

  const saveData = (property) => {
    const data = {
        propertyKey: property.pkey,
        syndicateMedia: "" + imageRef.current.checked,
        syndicationReady: "" + readyRef.current.checked
    };
    
    http.callApi('PUT', AppConfig.uri.updateProduct, data)
        .then((response) => {
            property.syndicateMedia = response.syndicateMedia;
            property.syndicationReady = response.syndicationReady;
            configAsideRef.current.close();
            setIsSaved(true);
            setMessage("Changes to the Syndication Product configuration are saved successfully");
      }).catch((message) => {
        configAsideRef.current.close();
        setIsSaved(true);
        setMessage("Error while saving Syndication product configuration.");
      });
    };

  return (
    <div>
      <raul-button variant = "control" size = "small" id="medium" class="r-float-right r-ml-4" ref={btnRef}>
        <raul-icon icon="pencil" kind="consumer" class="r-icon-sm"></raul-icon>
        Syndication Settings
      </raul-button>

      <raul-aside id="synd-settings" size="medium" ref={asideRef}>   
        <raul-aside-header>
          <p class="r-pb-2 r-font-semibold r-text-md">{props.property ? props.property.propertyName : '' }</p>            
          <raul-aside-title class="r-font-bold">Syndication Settings</raul-aside-title>
        </raul-aside-header>

        <raul-aside-body>
          <div class="r-font-bold r-text-lg r-border-b-3 r-border-gray-medium r-pb-2">Property Information</div>

          <div class="r-border-b r-border-gray-medium r-font-medium r-pb-5 r-pt-5">
            <p class="r-opacity-80 r-text-md">{props.property ? props.property.propertyName : '' }</p>
            <p class="r-opacity-80 r-text-md ">Property Key:#{props.property ? props.property.pkey : '' }</p>
          </div>

          <div class="r-border-b r-border-gray-medium r-font-medium r-pb-5 r-pt-5">
            <p>Address</p>
            <div class="r-opacity-80 r-text-md">
              <p>{props.property ? props.property.addressLine1 : '' }</p>
              <p>{props.property ? props.property.addressLine2 : '' }</p>
              <p>{props.property ? props.property.cityName : ''}, {props.property ? props.property.stateName : ''} {props.property ? props.property.zipCode : ''}</p>
            </div>
          </div>
          
          <div class="r-pb-5 r-pt-5">
            <span> Marketing Status 
              <raul-tooltip class="r-text r-cursor-pointer" text="Marketing Status signals whether the property has any active floor plans.<br/> <br/> Not having any floor plans would prevent listings from syndicating." placement="top">
                  <raul-icon icon="interface-information" class="r-icon-xl r-ml-2"></raul-icon>
              </raul-tooltip>
            </span>
            <span class="r-pl-4 raul-status">
              <div className ={props.property.marketReady === 'true' ? 'r-status r-status--success' : 'r-status r-status--destructive'}>
                    <div title={props.property.marketReady === 'true' ? 'On' : 'Off'}>{props.property.marketReady === 'true' ? 'On' : 'Off'}</div>
                </div>
            </span>
          </div>

          <div class="r-font-bold r-text-lg r-border-b-3 r-border-gray-medium r-pb-2">
            <span>
              <span>Sending Syndication</span>
              <raul-icon icon="pencil" kind="consumer" class="r-icon-xl r-float-right r-cursor-pointer" onClick={() => openEditConfig(props.property)} ></raul-icon>
            </span>
          </div>

          <div class="r-text-md r-font-medium r-pt-5 r-pb-5 r-border-b r-border-gray-medium">
            <p class="r-opacity-80">
              Send Ready
              <div class="r-float-right raul-status">
                <div className ={props.property ? props.property.syndicationReady === 'true' ? 'r-status r-status--success' : 'r-status r-status--destructive' : 'r-status r-status--destructive'}>
                    <div title={props.property ? props.property.syndicationReady === 'true' ? 'Yes' : 'No' : 'No'}>{props.property ? props.property.syndicationReady === 'true' ? 'Yes' : 'No' : 'No'}</div>
                </div>
              </div>
            </p>
          </div>

          <div class="r-text-md r-font-medium r-pt-5 r-pb-5 r-border-b r-border-gray-medium">
            <p class="r-opacity-80">
              Sending Images
              <div class="r-float-right raul-status">
                <div className ={props.property ? props.property.syndicateMedia === 'true' ? 'r-status r-status--success' : 'r-status r-status--destructive': 'r-status r-status--destructive'}>
                    <div title={props.property ? props.property.syndicateMedia === 'true' ? 'Yes' : 'No' : 'No'}>{props.property ? props.property.syndicateMedia === 'true' ? 'Yes' : 'No' : 'No'}</div>
                </div>
              </div>
            </p>
          </div>

          {
            isSaved && message.length > 0 && <div class="r-mt-3">
            <raul-alert
              heading=""
              content={message}
              rounded
              variant={message.includes("Error") ? "danger" : "success"}
            ></raul-alert>
            </div>
          }
          
        </raul-aside-body>

        <raul-aside-footer>
          <raul-aside-actions>
            <raul-aside-actions>
              <raul-button class="close-aside" variant="primary" id="medium-aside-close" ref={closeRef}>
                Close
              </raul-button>           
            </raul-aside-actions>
          </raul-aside-actions>
        </raul-aside-footer>
        <raul-aside id="small-aside" slot="secondary-aside" size="small" ref={configAsideRef}>
                <raul-aside-header>
                    <raul-aside-sub-title class="r-font-semibold r-text-sm">{props?.property?.propertyName}</raul-aside-sub-title>
                    <raul-aside-title  class="r-mt-1 r-text-black">Edit Configuration
                        <a href='http://onesitehelp.realpage.com/?env=on&vr=40&ScrVer=350&pg=/mc/company/companyId/syndication/edit' target='_blank'>
                            <raul-icon icon="interface-information" class="r-icon-xl r-ml-3"></raul-icon>
                        </a>
                    </raul-aside-title>
                </raul-aside-header>

                <raul-aside-body>
                    <div>
                        <raul-checkbox label-text="Sending Images" class="r-mb-4">
                            <input type="checkbox" ref={imageRef} />
                        </raul-checkbox>
                    </div>
                    <div>
                        <raul-checkbox label-text="Send Ready" class="r-mb-4">
                            <input type="checkbox" ref={readyRef}/>
                        </raul-checkbox>
                    </div>
                </raul-aside-body>

                <raul-aside-footer>
                    <raul-aside-actions>
                        <raul-button class="close-aside" variant="secondary" id="small-aside-close" ref={closeBtnRef}>
                        Close
                        </raul-button>
                        <raul-button class="close-aside r-ml-2" variant="primary" id="small-aside-close" onClick={() => saveData(props.property)}>
                        Save
                        </raul-button>
                    </raul-aside-actions>
                </raul-aside-footer>
            </raul-aside>
      </raul-aside>
    </div>
  );
};

export default Settings;
